import React from 'react';
import './Video.css';
import VideoLive from './VideoLive';
import btnbongda from '../../../assets/image/btnbongda.webp'
import calendar from '../../../assets/image/calendar.webp'
import ball from '../../../assets/image/ball.webp'
import field from '../../../assets/image/field.webp'
import location from '../../../assets/image/location.webp'
const Video = ({ data }) => {

    return (
        <div className='video'>
            <div className='mt-3'>
                {/* <img src={cup} width='14%' h alt="" />  */}
            </div>
            <div className='text-center video_livestream'>
                <img className='imgbongdabtn' src={btnbongda} width='20%' alt="" />
                {/* <button className='video_btn'>BÓNG ĐÁ</button> */}
                <div className='video_stream '>
                    <div className='video_text'>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon'>
                            <div className='img_football_match'>
                                <img width='15vw'  src={calendar} alt="" />
                            </div>
                            <p className='m-0'>
                                Date
                            </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon'>

                            <div className='img_football_match'>
                                <img width='15vw' src={ball} alt="" />
                            </div>
                            <p className='m-0'>
                                Match
                            </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon'>

                            <div className='img_football_match'>
                                <img width='15vw' src={field} alt="" />
                            </div>
                            <p className='m-0'>
                                Tiket Price
                            </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon'>

                            <div className='img_football_match'>
                                <img width='15vw' src={location} alt="" />
                            </div>
                            <p className='m-0'>
                                Venue
                            </p>
                        </div>
                    </div>
                    <div className='video_match_live '>
                        {data && (
                           <><VideoLive data={data}></VideoLive></> 
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
   


export default Video;
