import React from 'react';
import './Match.css'
import MatchVideo from '../matchvideo/MatchVideo';
const Match = () => {
    return (
        <div className=' match_main '>
            <div className='container'>
            <div className='container'>
                <div className='match_text '>
                    <h4 className=''>CẬP NHẬT  <span style={{color:'rgb(255 141 0) '}}> TRẬN HOT</span></h4>
                    <h4></h4>
                    {/* <p>Upcoming Tournaments And Matches</p> */}
                    <span className="h8">GIẢI ĐẤU VÀ TRẬN ĐẤU SẮP TỚI</span>
                </div>
                <MatchVideo></MatchVideo>
            </div>
            </div>
        </div>
    );
}

export default Match;
