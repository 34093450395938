import React from 'react';
import './MatchPc.css'
import MatchVideoPc from '../matchvideoPc/MatchVideoPc';
const MatchPc = ({ data }) => {

    if (data) return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className=' match_main_Pc ' style={{ width: '90%' }}>
                <div className='px-5' >
                    <div className=''>
                        {/* <div className=' d-flex match_text_main_Pc justify-content-between align-items-start'>
                    <div className='match_text_Pc '>
                        <h3 className=''>CẬP NHẬT <span>TRẬN THI ĐẤU HOT</span></h3>
                        <p>GIẢI ĐẤU VÀ TRẬN ĐẤU SẮP TỚI</p>
                    </div>
                    <div  style={{marginTop:'50px'}}>
                        <div className='me-1'>
                            <button className="btn_leauge_Pc active">Premier</button>
                            <button className="btn_leauge_Pc">LaLiGa</button>
                            <button className="btn_leauge_Pc">FA</button>
                            <button className="btn_leauge_Pc">Bundesliga</button>
                        </div>
                    </div>
                    </div> */}
                        <MatchVideoPc data={data}></MatchVideoPc>

                    </div>
                </div>

            </div>
        </div>

    );
}

export default MatchPc;
