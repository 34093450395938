import React from 'react';
import './Header.css';
import logo from '../../assets/image/mainlogo.webp';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <a href="/">
                <img src={logo} alt="500AE Logo" className="header_logo" />
            </a>
            <nav className="nav_bar mt-2">
                <NavLink to="/" className="nav_link  mx-2">TRANG CHỦ</NavLink>
                <NavLink to="/soikeo" className="nav_link mx-2">TRẬN ĐẤU SẮP TỚI</NavLink>
                <NavLink to="/bxh" className="nav_link mx-2">BXH</NavLink>
                <NavLink to="https://500ae.name" className="nav_link mx-2">TIN TỨC THỂ THAO</NavLink>

            </nav>
        </header>
    );
};

export default Header;
