import { React, useEffect, useState } from 'react';
import useStore from '../../store/useStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import './PartnerLivePc.css';
import KenhNhaDaiPc from '../../../assets/image/kenhnhadaiPc.webp';
import vs from '../../../assets/image/vs.webp';
import match_partner from '../../../assets/image/match_partner.webp';
import aepartner from '../../../assets/image/500aepartner.webp';

const PartnerLivePc = (dataSlide) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const setVideoList = useStore((state) => state.setVideoList);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/rooms/partnerlive`;
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('CHƯA CÓ TRẬN ĐẤU NÀO CỦA KÊNH NHÀ ĐÀI' + response.statusText);
      }
      const data = await response.json();
      setData(data);
      setVideoList(data);
    } catch (error) {
      setError(error.message);
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const getDayOfWeek = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <div className='text-center'>{error}</div>;
  }

  if (data) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <div className='main_carousel' style={{ width: '90%' }}>
          <div className="carousel-container mb-5">
            <img src={KenhNhaDaiPc} width="100%" alt="" />
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={30}
              navigation
              autoplay={{ delay: 3500 }}
              pagination={{ clickable: true }}
              breakpoints={{
                900: { // For screens larger than 900px
                  slidesPerView: 4,
                },
                0: { // For screens less than 900px
                  slidesPerView: 3,
                },
              }}
              className='pb-5'
            >
              {data.map((match) => (
                <SwiperSlide key={match.matchId}>
                  <div className="match-card text-center">
                    <div className="d-flex justify-content-around">
                      <div>
                        <button className="watch-button">Group Match</button>
                      </div>
                      <div>
                        <p className="date">{getDayOfWeek(match.timestamp)}</p>
                        <p className="time">{match.startTime}</p>
                      </div>
                    </div>
                    <div
                      className="teams d-flex justify-content-between align-items-start text-center px-3"
                      style={{ backgroundColor: '#020b81', padding: '60px 0' }}
                    >
                      <div className="text-center">
                        <img
                          src={match.localteam_logo}
                          className="imglogo_partnerPc"
                          width="50px"
                          height="50px"
                          alt=""
                        />
                        <p className="m-0">{truncateText(match.localteam_title, 10)}</p>
                      </div>
                      <img src={vs} width="10%" height="10%" alt="" style={{ marginTop: '15px' }} />
                      <div className="text-center">
                        <img
                          src={match.visitorteam_logo}
                          className="imglogo_partnerPc"
                          width="50px"
                          height="50px"
                          alt=""
                        />
                        <p className="m-0">{truncateText(match.visitorteam_title, 10)}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="partner_live_des">
                        <p>{match.league_title}</p>
                        <p>Chiếu Tại 500AE</p>
                      </div>
                      <div>
                        <img src={match_partner} alt="" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-around pb-3 align-items-center">
                      <div>
                        <a
                          className="watch-button text-light text-decoration-none"
                          href={`/videopartner/${match.matchId}`}
                        >
                          Xem ngay <i className="fa-regular fa-circle-play ms-1"></i>
                        </a>
                      </div>
                      <img src={aepartner} width="20%" height="20%" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <p>...LOADING KÊNH NHÀ ĐÀI</p>
      </div>
    );
  }
};

export default PartnerLivePc;
