import { React, useEffect, useState } from 'react';
import BannerPc from './Banner/BannerPc';
import MatchPc from './matchPc/MatchPc';
import CommunityPc from './communitypc/CommunityPc';
import StoryPc from './storyPc/StoryPc';
import PartnerLivePc from './partnerlive/PartnerLivePc'
const HomePagePc = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms/list`);
                const result = await response.json();
                const filteredDataCdn = result.rooms.filter(match => match.cdnlink !== "1")
                    .sort((a, b) => {
                        // Combine date and time into a single Date object for both a and b
                        const dateTimeA = new Date(`${a.date}T${a.time}`);
                        const dateTimeB = new Date(`${b.date}T${b.time}`);

                        // Sort by the combined date and time
                        return dateTimeA - dateTimeB;
                    });


                setData(filteredDataCdn);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return <div>Loading...</div>;
    }
    if (data) {
        if (data.length > 1) {
            return (
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{ width: '100%'}}>
                        <MatchPc data={data}></MatchPc>
                        <PartnerLivePc ></PartnerLivePc>
                        <CommunityPc></CommunityPc>
                        <StoryPc></StoryPc>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='d-flex justify-content-center align-items-center' >
                    <div style={{ width: '100%' }}>
                        <MatchPc data={data}></MatchPc>
                        <PartnerLivePc ></PartnerLivePc>
                        <CommunityPc></CommunityPc>
                        <StoryPc></StoryPc>
                    </div>
                </div>
            );
        }
    }
}
export default HomePagePc;
