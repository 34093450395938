import React, { useEffect, useState } from 'react';
import './MatchVideoPc.css';
import '../matchPc/MatchPc.css'
import VideoPc from '../VideoPc/VideoPc';
import MatchSchedulePc from '../matchtimePc/MatchSchedulePc';
import footballmp4 from '../../../assets/video/football.mp4'
import PartnerLiveAtTopPc from '../partnerlive/PartnerLiveAtTopPc';
const videoOriginal = [{ cdnlink: '' }, { cdnlink: footballmp4 }];
const dataSlide = 2;
const MatchVideoPc = ({ data }) => {

    if (data) {
        if (data.length > 1) {
            return (
                <div className='match_video_Pc d-flex justify-content-between align-items-start mt-5'>

                    {data && (
                        <>
                            <div style={{ width: '40%' }}>
                                <div className='match_text_Pc '>
                                    <h3 className=''>CẬP NHẬT <span>TRẬN THI ĐẤU HOT</span></h3>
                                    <p>GIẢI ĐẤU VÀ TRẬN ĐẤU SẮP TỚI</p>
                                </div>
                                <MatchSchedulePc data={data}></MatchSchedulePc>
                            </div>
                            <div className='mt-2' style={{ width: '55%' }}>
                                <div className='me-1'>
                                    <button className="btn_leauge_Pc active">Premier</button>
                                    <button className="btn_leauge_Pc">LaLiGa</button>
                                    <button className="btn_leauge_Pc">Seria</button>
                                    <button className="btn_leauge_Pc">Bundesliga</button>
                                    <button className="btn_leauge_Pc">League 1</button>
                                </div>
                                <VideoPc data={data}></VideoPc>
                            </div>

                        </>
                    )}

                </div>



            );
        } else {
            return <div className='match_video_Pc d-flex justify-content-between align-items-start mt-5'>

                {data && (
                    <>

                        <div style={{ width: '40%' }}>
                            <div className='match_text_Pc '>
                                <h3 className=''>CẬP NHẬT <span>TRẬN THI ĐẤU HOT</span></h3>
                                <p>GIẢI ĐẤU VÀ TRẬN ĐẤU SẮP TỚI</p>
                            </div>
                            <PartnerLiveAtTopPc dataSlide={dataSlide}></PartnerLiveAtTopPc>
                        </div>
                        <div className='' style={{ width: '55%' }}>
                            <div className=''>
                                <button className="btn_leauge_Pc active">Premier</button>
                                <button className="btn_leauge_Pc">LaLiGa</button>
                                <button className="btn_leauge_Pc">Seria</button>
                                <button className="btn_leauge_Pc">Bundesliga</button>
                                <button className="btn_leauge_Pc">League 1</button>
                            </div>
                            <VideoPc data={videoOriginal}></VideoPc>
                        </div>
                    </>
                )}
            </div>


        }
    }
};

export default MatchVideoPc;
