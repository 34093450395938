import React from 'react';
import './CommunityPc.css';
import innesta from '../../../assets/image/innesta.webp'
const CommunityPc = () => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
             <div className='mt-5 d-flex justify-content-between px-5' style={{width:'90%'}}>
            <div className='img_innesta' >
                <img src={innesta} width="100%" alt="" />
            </div>
            <div >
                <div className="text-end main_text_community_Pc">
                    <h1>CỘNG ĐỒNG </h1>
                    <h1>ĐAM MÊ</h1>
                    {/* <p>Community Of Passion</p> */}
                </div>
                <div className="d-flex justify-content-center">
                    <div className="community_one_Pc ">
                        <a href="https://t.me/TIP500AE" className='text-decoration-none text-light'>
                            <div className="community_box_one_Pc">
                                <div className="community_color_Pc">
                                    <h1>KÈO</h1>
                                    <h6>EURO TELE</h6>
                                </div>
                            </div>
                        </a>
                        <a href="https://www.facebook.com/500AESHBET/" className='text-decoration-none text-light'>
                            <div className="community_box_two_Pc text-center">
                                <h5>KÈO BÓNG</h5>
                                <h5>FACEBOOK</h5>
                            </div>
                        </a>
                    </div>
                    <div className="community_two_Pc">

                        <a href=" https://urlvn.net/dudoannhancd57k" className='text-decoration-none text-light'>
                            <div className="community_box_two_Pc text-center">
                                <h5>DỰ ĐOÁN </h5>
                                <h5>NHẬN QUÀ</h5>
                            </div>
                        </a>
                        <a href=" https://urlvn.net/dudoannhancd57k" className='text-decoration-none text-light'>
                            <div className="community_box_one_Pc">
                                <div className="community_color_Pc">
                                    <h6>KHUYẾN MÃI</h6>
                                    <h1>HOT</h1>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default CommunityPc;
