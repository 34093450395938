import React from 'react';
import './StoryPc.css'
import largeLogo from '../../../assets/image/2.png'

const StoryPc = () => {
    return (
        <div>
            <img src={largeLogo} alt="" width='100%'/>
        </div>
    );
}

export default StoryPc;
