import React from 'react';
import './FooterPc.css'
import footerpc from '../../assets/image/footerbackround.png'

const FooterPc = () => {
  return (
    <footer className="footer">
      <img src={footerpc} alt="" width='100%' height='100%' />
    </footer>
  );
}

export default FooterPc;
