import React from 'react';
import Banner from './banner/Banner';
import Match from './match/Match';
import Community from './community/Community'
import Story from './story/Story';
import './Homepage.css';
import PartnerLive from './partnerlive/PartnerLive';

const HomePage = () => {
   return (
      <div className='homepage pb-5 mt-5 container'>
         <div className=' pb-5'>
            {/* <Banner></Banner> */}
            <div >
               <Match />
               <Community />
               <Story />
               <PartnerLive />
            </div>
         </div>
      </div>
   );
}

export default HomePage; 
